import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class ContactOnlyGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/login']);
      return false;
    }
  
    const roles = this.auth.getUserRoles();
    const hasContactRole = roles.includes('CONTACT');
    const isContactRoute = state.url.startsWith('/contacto');
  
    if (hasContactRole && !isContactRoute) {
      this.router.navigate(['/contacto/eventos'], {
        queryParams: { redirected: 'true' }
      });
      return false;
    }
  
    return true;
  }
  
  
  
}
